@import url("https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans&family=Roboto&family=Montserrat&display=swap");

.banner-header {
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: bold;
    line-height: 48px;
    position: absolute;
    color: #57BC6E;
}

/* Mobile styles */
@media (max-width: 600px) {

    .banner-desc {
        font-family: 'Montserrat';
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        color: #ffffff;
        position: absolute;
        margin-top: 20%;
    }

    .home-banner-content {
        position: absolute;
    }

    .section-header {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 45%;
        height: 5%;
    }

}

/* Desktop styles */
@media (min-width: 601px) {
    .banner-desc {
        font-family: 'Montserrat';
        font-size: 22px;
        font-weight: 600;
        line-height: 29px;
        color: #ffffff;
        position: absolute;
        margin-top: 5%
    }

    .home-banner-content {
        position: absolute;
    }

    .section-header {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 38%;
        height: 5%;
    }

}