.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CustomPopup.css */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px; /* Adjust width as needed */
  max-width: 90%; /* Ensure it's responsive */
}

.popup-content {
  text-align: center;
}

.popup-header {
  margin-bottom: 10px;
}

.popup-body {
  margin-bottom: 20px;
}

.popup-footer {
  display: flex;
  justify-content: center;
}

.install-button, .close-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}

.install-button {
  background-color: #4CAF50;
  color: white;
}

.close-button {
  background-color: #ccc;
  color: black;
}
