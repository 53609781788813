.form-footer-header {
    font-family: 'Lora';
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    color: #57BC6E;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    width: 100vh;
}

.form-donate-now {
    width: 942px;
    height: 750px;
    padding: 8%;
    padding-top: 5%;
    background-color: #f1f1f1;
    overflow-x: hidden;
    overflow-y: scroll;
}

.box-button {
    width: 100%;
    height: 20%;
    position: static;
    background-color: #f1f1f1;
    z-index: 1000;
    bottom: auto;
}

.joditor {
    margin-top: 1%;
}